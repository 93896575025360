/**
 * Implementation of Cookie management with the jQuery Cookies plugin.
 *
 * @type {Object}
 */
aaaie.Cookies = {
  config: {
    json: true
  },
  set: function(key, value, options) {
    'use strict';
    aaaie.$.cookie(key, value, aaaie.$.extend(this.config, options));
  },
  get: function(key, options) {
    'use strict';
    return aaaie.$.cookie(key, undefined, aaaie.$.extend(this.config, options));
  },
  delete: aaaie.$.removeCookie
};

aaaie.Cookies.set = deprecate(
  aaaie.Cookies.set,
  'aaaie.Cookies.set() is deprecated. Use document.cookie instead.'
);
aaaie.Cookies.get = deprecate(
  aaaie.Cookies.get,
  'aaaie.Cookies.get() is deprecated. Use document.cookie instead.'
);
aaaie.Cookies.delete = deprecate(
  aaaie.Cookies.delete,
  'aaaie.Cookies.delete() is deprecated. Use document.cookie instead.'
);
