/**
 * Implementation of URL Parsing with the jQuery plugins.
 *
 * @type {Object}
 */
/*
  Parse URL using JCL.
  No parameters to be passed into the function unless the "parseURI" function is used,
  in which case, a logger comment will get generated to notify developers to use aaaie.Url.parse.
 */
aaaie.Url = {
  config: {},

  parse: function(location) {
    return purl(location);
  },

  /**
   * get parameter from url based on anchor tag `#`
   * @param name
   * @param url
   * @returns {string}
   */
  getParameterByNameFromUrl: function(name, url) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('#' + name + '=([^&#]*)'),
      results = regex.exec(url);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
};

if (typeof parseUri !== 'undefined') {
  var oldParseUri = parseUri;

  parseUri = function(location) {
    if (aaaie.debug) {
      // eslint-disable-next-line no-console
      console.warn(
        'parseUri is deprecated as of 0.2.7. Please use aaaie.Url.parse instead.'
      );
    }
    return oldParseUri(location);
  };

  parseUri.options = oldParseUri.options;
}

if (!window.location.origin) {
  window.location.origin =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');
}
